import { PropsWithChildren } from "react";

// function Subscription({
//   children,
//   numbers,
//   cost,
//   roboID,
//   numberDesc = 'годовая подписка',
// }: PropsWithChildren<{
//   numbers: string;
//   numberDesc?: string;
//   cost: number;
//   roboID: string;
// }>) {
//   return (
//     <>
//       <h3>{children}</h3>
//       <table>
//         <tr style={{ textAlign: "center" }}>
//           <td>
//             Печатная версия
//             <a href="#footnote-2" className="footnote">
//               2
//             </a>
//           </td>
//           <td>
//             {numbers}
//             <br />
//             {numberDesc}
//           </td>
//           <td>
//             <a
//               className="forceLinkUnderline"
//               href={`https://robo.market/product/${roboID}`}
//             >
//               {cost}
//             </a>{" "}
//             руб.
//           </td>
//         </tr>
//       </table>
//     </>
//   );
// }

function SubscriptionGroup({ journal, children }: PropsWithChildren<{ journal: string }>) {
  return <>
    <h3>{journal}</h3>
    <table style={{ border: 'var(--border)', borderCollapse: 'collapse' }}>
      {children}
    </table>
  </>
}

function Subscription({ type, issues, cost, description = 'годовая подписка', marketplaceId }: {
  type: 'print'
  issues: number;
  cost: number;
  description?: string
  marketplaceId?: string;
}) {
  return (
    <tr style={{ textAlign: "center", borderBottom: 'var(--border)' }} >
      <td style={{ padding: '10px 0px' }}>
        {type === 'print' ? <>
          Печатная версия
          <a href="#footnote-2" className="footnote">
            [2]
          </a>
        </> : null}
      </td>
      <td style={{ padding: '10px 0px' }}>
        {issues}
        {!!description ? <><br />({description})</> : null}
      </td>
      <td style={{ padding: '10px 0px' }}>
        {!!marketplaceId ? <a className="forceLinkUnderline" href={`https://robo.market/product/${marketplaceId}`}>{cost}</a>
          : <span>{cost}</span>}
        {" "}
        руб.
      </td>
    </tr>
  );
}

function ExternalSubscription({
  children,
  links,
}: PropsWithChildren<{
  links: [string, string, ...string[]][]
}>) {
  return (
    <>
      <h3>{children}</h3>
      <ul>
        {links.map(([alias, link, ...other]) => <li><a href={link}>{alias}</a>{other}</li>)}
      </ul>
    </>
  )
}

export function SubscriptionPage() {
  return (
    <article
      style={{
        padding: 24,
        display: "flex",
        flexFlow: "column wrap",
        gap: "1em",
      }}
    >
      <h3>Бесплатная электронная подписка это:</h3>
      <ul>
        <li>Доступ к последнему номеру журнала до его выхода из печати</li>
        <li>Постатейный доступ к содержанию</li>
        <li>Сканирование в формате PDF, распечатка и копирование</li>
        <li>Возможность формировать ссылки для цитирования</li>
      </ul>
      <i>
        На сайте журнала регистрируйтесь как Читатель и получайте уведомления о
        новых номерах
      </i>
      <h1>
        Подписка на 2024 г. через сайт издательства
        <a href="#footnote-1" className="footnote">
          [1]
        </a>
      </h1>

      {/* <Subscription numbers="12 номеров" cost={4200} roboID="1685361"> */}
      {/*   Кардиоваскулярная терапия и профилактика */}
      {/* </Subscription> */}
      {/* <Subscription numbers="12 номеров" cost={4200} roboID="414341"> */}
      {/*   Российский кардиологический журнал */}
      {/* </Subscription> */}
      {/* <Subscription numbers="3 номера" numberDesc="(первое полугодие)" cost={1500} roboID=""> */}
      {/*   Рациональная Фармакотерапия в Кардиологии */}
      {/* </Subscription> */}
      {/* <Subscription numbers="3 номера" numberDesc="(второе полугодие)" cost={1500} roboID=""> */}
      {/*   Рациональная Фармакотерапия в Кардиологии */}
      {/* </Subscription> */}
      {/* <Subscription numbers="6 номера" numberDesc="(одие)" cost={1500} roboID=""> */}
      {/*   Рациональная Фармакотерапия в Кардиологии */}
      {/* </Subscription> */}




      <SubscriptionGroup journal="Кардиоваскулярная терапия и профилактика">
        <Subscription type="print" issues={12} cost={4200} marketplaceId="1685361" />
      </SubscriptionGroup>
      <SubscriptionGroup journal="Российский кардиологический журнал">
        <Subscription type="print" issues={12} cost={4200} marketplaceId="414341" />
      </SubscriptionGroup>
      <SubscriptionGroup journal="Российский журнал истории Церкви">
        {/* <Subscription type="print" issues={12} cost={4200} marketplaceId="2183541" /> */}
        <Subscription type="print" issues={4} cost={1200} marketplaceId="2183541" />
      </SubscriptionGroup>
      <SubscriptionGroup journal="Рациональная Фармакотерапия в Кардиологии">
        <Subscription type="print" issues={3} description="первое полугодие" cost={1500} marketplaceId="3011697" />
        <Subscription type="print" issues={3} description="второе полугодие" cost={1500} marketplaceId="3011698" />
        <Subscription type="print" issues={6} description="годовая" cost={3000} marketplaceId="3011694" />
      </SubscriptionGroup>


      <h1>
        Подписка через подписные агенства
      </h1>

      <ExternalSubscription links={[
        ['Пресса России', 'https://www.pressa-rf.ru/cat/1/edition/f42432/'],
        ['Книга-Сервис', 'https://www.akc.ru/itm/rossiyskiy-kardiologic_heskiy-z_hurnal/'],
        // ['Урал Пресс', 'https://ural-press.ru/catalog/97209/8656538/?sphrase_id=374002', ', можно оформить на отдельный номер'],
        ['Урал Пресс', 'https://www.ural-press.ru/catalog/97209/8936900/'],
      ]}>
        Российский кардиологический журнал
      </ExternalSubscription>


      <ExternalSubscription links={[
        ['Пресса России', 'https://www.pressa-rf.ru/cat/1/edition/f42434/'],
        ['Книга-Сервис', 'https://www.akc.ru/itm/kardiovaskuly_arnay_a-terapiy_a-i-profilaktika/'],
        // ['Урал Пресс', 'https://ural-press.ru/catalog/97209/8695696/?sphrase_id=374003', ', можно оформить на отдельный номер'],
        ['Урал Пресс', 'https://www.ural-press.ru/catalog/97209/8934126/'],
      ]}>
        Кардиоваскулярная терапия и профилактика
      </ExternalSubscription>

      <ExternalSubscription links={[
        ['Пресса России', 'https://www.pressa-rf.ru/cat/1/edition/e33097/'],
        ['Книга-Сервис', 'https://www.akc.ru/itm/rossiyskiy-z_hurnal-istorii-t_serkvi/'],
        // ['Урал Пресс', 'https://ural-press.ru/catalog/97266/8678529/?sphrase_id=374004'],
        ['Урал Пресс', 'https://www.ural-press.ru/catalog/97266/8937544/'],
      ]}>
        Российский журнал истории Церкви
      </ExternalSubscription>
      <ExternalSubscription links={[
        ['Пресса России', 'https://www.pressa-rf.ru/cat/1/edition/e81309/'],
        ['Книга-Сервис', 'https://www.akc.ru/itm/rat_sionalnay_a-farmakoterapiy_a-v-kardiologii/'],
        ['Урал Пресс', 'https://www.ural-press.ru/catalog/97209/8941380/'],
      ]}>
        Рациональная Фармакотерапия в Кардиологии
      </ExternalSubscription>
      <br />
      <hr />

      <span id="footnote-1">
        <span className="footnote">[1]</span> Стоимость подписки по цене
        издательства. Оплата подписки осуществляется электронным платежом через
        ROBOKASSA (Visa, Mastercard, мобильным телефоном - МТС, Мегафон, Билайн,
        всеми электронными валютами, наличными через сеть терфиналов, через
        интернет-банки и другими способами).
      </span>
      <span id="footnote-2">
        <span className="footnote">[2]</span> Только по Российской Федерации
      </span>
    </article>
  );
}
